import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Spinner,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate, useParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import { news_map_data } from "../../data/new_generated/news_map";
import Plg from "../../Components/Typography/Text/Plg";
import { news_detail } from "../../data/new_generated/news_detail";
import ButtonCircularIcon from "../../Components/Button/ButtonCircularIcon";
import AskBudAccent from "../../Components/AskBudAccent";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import ButtonText from "../../Components/Button/ButtonText";
import { HighlightMenu } from "react-highlight-menu";
import Markdown from "react-markdown";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
import { CgNotes } from "react-icons/cg";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa6";
import { clear } from "localforage";
import BackArrow from "../../Components/backArrow";

const NewsReading = (props) => {
  const { topic, subject, area, noteid, id } = useParams();

  const [newsData, setNewsData] = useState({});
  const [isFav, setIsFav] = useState(false);
  const [savedNewsData, setSavedNewsData] = useState({});
  const [quickexpText, setQuickExpText] = useState("");
  const explanationModalStates = useDisclosure();

  const [isExplainationLoading, setIsExplainationLoading] = useState(false);

  const navigate = useNavigate();

  const [forceOpen, setForceOpen] = useState(false);
  const [initText, setInitText] = useState("");
  const toast = useToast();
  const username = useSelector((state) => state.user.username);
  const [topicDetail, setTopicDetail] = useState({});

  const [relatedNews, setRelatedNews] = useState([]);
  const [isRelatedNewsLoading, setIsRelatedNewsLoading] = useState(false);
  const [relatedNotes, setRelatedNotes] = useState([]);
  const [issRelatedNotesLoading, setIsRelatedNotesLoading] = useState(false);
  const [isRelatedItemDetailsLoading, setIsRelatedItemDetailsLoading] =
    useState(false);
  const [selecetedNews, setSelectedNews] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [relatedNewsDetails, setRelatedNewsDetails] = useState([]);
  const [relatedNoteDetails, setRelatedNoteDetails] = useState([]);

  const notesModalStates = useDisclosure();
  const relatedNoteModalDisclosure = useDisclosure();
  const relatedNewsModalDisclosure = useDisclosure();
  const relatedNoteDetailsModalDisclosure = useDisclosure();
  const relatedNewsDetailsModalDisclosure = useDisclosure();

  console.log(selecetedNews, selectedNote, "selected");

  const getTopicDetails = async (username, topic_id) => {
    console.log("topic_id", topic_id);
    try {
      const res = await axios.get(
        `${NEW_API_URL}lessonplan/get_topic_detail/${username}/${topic_id}/`
      );
      console.log("topic details fetch succesful");
      return res?.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const getTrendingNewsData = async (username, n_days) => {
    try {
      const res = await axios.get(
        `${NEW_API_URL}feed/trending-news/${username}/${n_days}`
      );

      return res?.data;
    } catch (error) {
      console.log("Error fetching trending news data:", username, error);
      throw error;
    }
  };

  const getNewsDetails = async (username, news_id) => {
    try {
      const res = await axios.get(
        `${NEW_API_URL}feed/news_detail/${username}/${news_id}/`
      );

      setRelatedNewsDetails(res?.data);
      return res?.data;
    } catch (error) {
      console.log("Error fetching news data for id:", id, error);
      throw error;
    }
  };

  const getNotesDetails = async (note_id) => {
    try {
      const res = await axios.get(
        `${NEW_API_URL}notes/get_note/admin@claydeck.com/${note_id}/`
      );
      setRelatedNoteDetails(res?.data);

      return res?.data;
    } catch (error) {
      console.log("Error fetching note data for id:", note_id, error);
      throw error;
    }
  };
  console.log("note details", relatedNoteDetails);
  const fetchRelatedNotes = async () => {
    try {
      setIsRelatedNotesLoading(true);
      console.log("fetchRelatedNotes");
      const topicUUID = newsData?.topic_id;
      console.log("topicUUID", topicUUID);
      const response = await getTopicDetails(username, topicUUID);

      console.log("Topic Details:", response);
      if (response.topic_detail.concepts) {
        setRelatedNotes(response.topic_detail.concepts);
      } else {
        setRelatedNotes([]);
      }
    } catch (error) {
      console.error("Error fetching related notes:", error);

      setRelatedNotes([]);
    } finally {
      setIsRelatedNotesLoading(false);
    }
  };
  const fetchRealatedNotesDetails = useCallback(async (note_id) => {
    if (!note_id) return null;

    try {
      setIsRelatedItemDetailsLoading(true);
      const res = await getNotesDetails(note_id);
      return res || {};
    } catch (error) {
      console.error("Note details fetch error:", error);
      return {};
    } finally {
      setIsRelatedItemDetailsLoading(false);
    }
  }, []);

  const fetchRelatedNews = async () => {
    try {
      setIsRelatedNewsLoading(true);

      const response = await getTrendingNewsData(username, 180);

      console.log("hello", response);
      let matchingCluster;
      if (response[0]?.clusters) {
        matchingCluster = response[0].clusters.find(
          (cluster) => cluster.cluster_id === newsData?.cluster_id
        );
        console.log("matchingCluster", matchingCluster);

        // if (!matchingCluster) {
        //   window.alert(
        //     "No related news found. showing news as per the first cluster"
        //   );
        //   matchingCluster = response[0].clusters[0];
        // }
        // console.log("matchingCluster", matchingCluster);

        if (matchingCluster?.related_news) {
          const filteredRelatedNews = matchingCluster.related_news.filter(
            (news) => news.news_id !== newsData?.news_id
          );

          setRelatedNews(filteredRelatedNews);
        } else {
          setRelatedNews([]);
        }
      }
    } catch (error) {
      console.error("Error fetching related news:", error);

      setRelatedNews([]);
    } finally {
      setIsRelatedNewsLoading(false);
    }
  };
  const fetchRealatedNewsDetails = useCallback(
    async (news_id) => {
      if (!news_id) return null;
      try {
        setIsRelatedItemDetailsLoading(true);
        const res = await getNewsDetails(username, news_id);
        setIsRelatedItemDetailsLoading(false);
        return res;
      } catch (error) {
        setIsRelatedItemDetailsLoading(false);
        console.log("news details fetch error", error);
      }
    },
    [username]
  );

  const get_map_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL +
        "lessonplan/get_topic_detail/" +
        username +
        "/" +
        workspace +
        "/"
    );

    setTopicDetail(r.data.topic_detail);

    return r.data;
  };

  const get_news_data = async (username, workspace) => {
    const r = await axios.get(
      NEW_API_URL + "feed/news_detail/" + username + "/" + (props.noteid || noteid || id) + "/"
    );

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    setNewsData(r.data);
    console.log(r.data, "news data");

    return r.data;
  };

  const get_saved_news = async (username) => {
    const r = await axios.get(
      NEW_API_URL + "feed/get_saved_news/" + username + "/"
    );

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    setSavedNewsData(r.data);

    if (r.data) {
      for (let i = 0; i < r.data.length; i++) {
        // console.log(r.data[i].id === noteid, "favd", r.data[i], noteid);
        if (r.data[i].id === props.noteid || r.data[i].id === noteid || r.data[i].id === id) setIsFav(true);
        else setIsFav(false);
      }
      if (r.data.length === 0) {
        setIsFav(false);
      }
    }

    console.log(r.data, "saved news data");

    return r.data;
  };

  const save_news = async (username) => {
    const r = await axios.get(
      NEW_API_URL + "feed/get_saved_news/" + username + "/"
    );

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    setSavedNewsData(r.data);
    console.log(r.data, "saved news data");

    return r.data;
  };

  const [selectedText, setSelectedText] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    if (!id) get_map_data(username, topic);
    get_news_data(username, topic);

    get_saved_news(username);
  }, []);

  const [newsHeadline, setNewsHeadline] = useState(newsData.title);
  const [context, setContext] = useState(undefined);
  const [newsContent, setNewsContent] = useState(newsData.content);
  useEffect(() => {
    if (!id)
      axios
        .post(NEW_API_URL + "lessonplan/update_activity/", {
          user_id: username,
          topic_id: topic,
          activity_type: "news",
          activity_id: props.noteid || noteid,
          read_status: "Read",
        })
        .then((r) => {});
  }, []);

  const handleSave = async () => {
    try {
      const postedData = {
        user_id: username,
        news_id: newsData.news_id,
        title: newsData.title,
        summary: newsData.summary,
        icon: "",
        priority: newsData.priority,
        news_type: newsData.news_type,
        source: newsData.source,
        cluster_id: newsData.cluster_id,
        pub_date: newsData.pub_date,
      };
      console.log("post data", postedData);
      const res = await axios.post(
        `${NEW_API_URL}feed/post-saved-news/`,
        postedData
      );
      toast({
        title: "News saved.",
        description: "News has been saved successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      get_saved_news(username);
    } catch (error) {
      console.error("Error saving news:", error);
    }
  };
  const handleSaveDelete = async () => {
    try {
      const postedData = {
        user_id: username,
        news_id: newsData.news_id,
      };
      console.log("post data", postedData);
      const res = await axios.delete(`${NEW_API_URL}feed/delete-saved-news/`, {
        data: postedData,
      });
      toast({
        title: "News deleted.",
        description: "News has been deleted successfully",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });

      get_saved_news(username);
    } catch (error) {
      console.error("Error saving news:", error);
    }
  };

  const handleNewsSelect = (item) => {
    if (item) {
      setSelectedNews(item);
      fetchRealatedNewsDetails(item.news_id);
      relatedNewsDetailsModalDisclosure.onOpen();
    }
  };

  const handleNoteSelect = (item) => {
    if (item) {
      setSelectedNote(item);
      fetchRealatedNotesDetails(item.id);
      relatedNoteDetailsModalDisclosure.onOpen();
    }
  };

  const getPriorityofNews = (priority) => {
    if (priority === "Medium") {
      return {
        img: "/medium_priority.png",
        text: "Medium",
      };
    } else if (priority === "High") {
      return {
        img: "/high_priority.png",
        text: "High",
      };
    } else if (priority === "Low") {
      return {
        img: "/low_priority.png",
        text: "Low",
      };
    }
  };

  console.log("news details", newsData);

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
          // overflow={"scroll"}
        >
          <Box>
            <HighlightMenu
              styles={{
                backgroundColor: "#344675",
                borderRadius: "16px",
              }}
              menu={({ selectedText, setMenuOpen, setClipboard }) => {
                return (
                  <Flex bg={"#344675"} padding={0} borderRadius={"30px"}>
                    <ButtonText
                      fontWeight={"300"}
                      onClick={() => {
                        setQuickExpText("");
                        setSelectedText(selectedText);
                        explanationModalStates.onOpen();
                        setMenuOpen(false);
                        const payload = {
                          action: "doubt",
                          phrase: selectedText,
                          context:
                            "You are context giver bot for explaining the phrase or word",
                        };
                        setIsExplainationLoading(true);
                        axios
                          .post(
                            NEW_API_URL +
                              "gamification/instant_doubt_clarification/",
                            payload
                          )
                          .then((r) => {
                            setQuickExpText(r.data.message);
                            setIsExplainationLoading(false);
                            // setChat((prev)=>[...prev,{'from':'bud',text:r.data.message,time:new Date().toISOString()}])
                          });
                      }}
                    >
                      Quick Explanation
                    </ButtonText>
                    <Box border={"0.5px solid white"} h={"100%"} />

                    <ButtonText
                      fontWeight={"300"}
                      onClick={() => {
                        setForceOpen(true);
                        setInitText(
                          "You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " +
                            selectedText
                        );

                        setSelectedText(selectedText);
                        // setSelectedText(selectedText)
                        // notesModalStates.onOpen()
                        setMenuOpen(false);
                      }}
                    >
                      Ask Question
                    </ButtonText>
                    <Box border={"0.5px solid white"} h={"100%"} />
                    <ButtonText
                      fontWeight={"300"}
                      onClick={() => {
                        setSelectedText(selectedText);
                        notesModalStates.onOpen();
                        setMenuOpen(false);
                      }}
                    >
                      Add to Note
                    </ButtonText>
                  </Flex>
                );
              }}
              target={menuRef}
            />

            <Flex mt={props.isConceptReader?'0':"10vh"} alignItems={"center"}>
              {/* <ArrowBackIcon
                cursor={"pointer"}
                onClick={() => {
                  navigate(-1);
                }}
                marginLeft={"-65px"}
                marginRight={"20px"}
                boxSize={"45px"}
              /> */}
              {!props.isConceptReader && <BackArrow/>}

              <Box flex={1}>
                {!props.isConceptReader && <P2xl mb={0} ml={0} mr={0}>
                  <strong>News Article</strong>
                </P2xl>}
              </Box>

              {isFav && (
                <Image
                  cursor={"pointer"}
                  onClick={() => {
                    handleSaveDelete();
                  }}
                  src={"/favon.svg"}
                  width={28}
                  height={27}
                />
              )}
              {!isFav && (
                <Image
                  cursor={"pointer"}
                  onClick={() => {
                    handleSave();
                  }}
                  src={"/favoff.svg"}
                  width={28}
                  height={27}
                />
              )}
            </Flex>
            <hr />

            <Modal
              isOpen={relatedNoteModalDisclosure?.isOpen}
              onClose={relatedNoteModalDisclosure.onClose}
              isCentered
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent height="75vh" minW="30%">
                <ModalHeader
                  borderBottomWidth={"1px"}
                  borderBottomColor={"gray.200"}
                >
                  Related Notes
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody overflow={"auto"}>
                  {issRelatedNotesLoading ? (
                    <Center height="full">
                      <Spinner color="rgba(9, 98, 162, 1)" />
                    </Center>
                  ) : relatedNotes.length > 0 ? (
                    <>
                      {relatedNotes?.map((item, i) => (
                        <Fragment key={i}>
                          <Box
                            onClick={() => {
                              handleNoteSelect(item);
                            }}
                          >
                            <HStack
                              p={4}
                              bg="white"
                              borderRadius="md"
                              justify="space-between"
                              cursor="pointer"
                              onClick={() => {}}
                              _hover={{ bg: "gray.50" }}
                              align="center"
                            >
                              <HStack spacing={4} flex="1" maxW="90%">
                                <CgNotes
                                  size={20}
                                  color="rgba(9, 98, 162, 1)"
                                />
                                <Text
                                  isTruncated
                                  m={0}
                                  flex="1"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                >
                                  {item.title}
                                </Text>
                              </HStack>
                              <Box flexShrink={0}>
                                <IoChevronForwardCircleSharp
                                  size={20}
                                  color="rgba(9, 98, 162, 1)"
                                />
                              </Box>
                            </HStack>
                            {i < relatedNotes.length - 1 && <Divider my={2} />}
                          </Box>
                        </Fragment>
                      ))}
                    </>
                  ) : (
                    <Center height="full">
                      <Text>No related notes available!</Text>
                    </Center>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
            <Modal
              isOpen={relatedNoteDetailsModalDisclosure.isOpen}
              onClose={() => {
                relatedNoteDetailsModalDisclosure.onClose();
                setRelatedNoteDetails([]);
              }}
              isCentered
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent height="75vh" minW="36%" overflow={"auto"}>
                <ModalHeader
                  borderBottomWidth={"1px"}
                  borderBottomColor={"gray.200"}
                >{`${"Note" + " : "}${selectedNote?.title}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody overflow={"auto"}>
                  {isRelatedItemDetailsLoading ? (
                    <Center height="full">
                      <Spinner color="rgba(9, 98, 162, 1)" />
                    </Center>
                  ) : relatedNoteDetails.length > 0 ||
                    Object.keys(relatedNoteDetails).length > 0 ? (
                    <Box>
                      <Markdown>{relatedNoteDetails?.description}</Markdown>
                    </Box>
                  ) : (
                    <Center height="full">
                      <Pxl m={0}>No details available</Pxl>
                    </Center>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={relatedNewsModalDisclosure?.isOpen}
              onClose={relatedNewsModalDisclosure.onClose}
              isCentered
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent height="75vh" minW="30%">
                <ModalHeader
                  borderBottomWidth={"1px"}
                  borderBottomColor={"gray.200"}
                >
                  Related News
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody overflow={"auto"}>
                  {isRelatedNewsLoading ? (
                    <Center height="full">
                      <Spinner color="rgba(9, 98, 162, 1)" />
                    </Center>
                  ) : relatedNews.length > 0 ? (
                    <>
                      {relatedNews?.map((item, i) => (
                        <Fragment key={i}>
                          <Box onClick={() => handleNewsSelect(item)}>
                            <HStack
                              p={4}
                              bg="white"
                              borderRadius="md"
                              justify="space-between"
                              cursor="pointer"
                              onClick={() => {}}
                              _hover={{ bg: "gray.50" }}
                              align="center"
                            >
                              <HStack spacing={4} flex="1" maxW="90%">
                                <FaRegNewspaper
                                  size={20}
                                  color="rgba(9, 98, 162, 1)"
                                />

                                <Text
                                  isTruncated
                                  m={0}
                                  flex="1"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                >
                                  {item.title}
                                </Text>
                              </HStack>
                              <Box flexShrink={0}>
                                <IoChevronForwardCircleSharp
                                  size={20}
                                  color="rgba(9, 98, 162, 1)"
                                />
                              </Box>
                            </HStack>
                            {i < relatedNews.length - 1 && <Divider my={2} />}
                          </Box>
                        </Fragment>
                      ))}
                    </>
                  ) : (
                    <Center height="full">
                      <Text>No related news available!</Text>
                    </Center>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={relatedNewsDetailsModalDisclosure.isOpen}
              onClose={() => {
                relatedNewsDetailsModalDisclosure.onClose();
                setRelatedNewsDetails([]);
              }}
              isCentered
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent height="75vh" minW="36%">
                <ModalHeader
                  borderBottomWidth={"1px"}
                  borderBottomColor={"gray.200"}
                >{`${"News" + " : "}${selecetedNews?.title}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody overflow={"auto"}>
                  {isRelatedItemDetailsLoading ? (
                    <Center height="full">
                      <Spinner color="rgba(9, 98, 162, 1)" />
                    </Center>
                  ) : relatedNewsDetails.length > 0 ||
                    Object.keys(relatedNewsDetails).length > 0 ? (
                    <Box>
                      <Markdown>
                        {relatedNewsDetails?.generated_summary}
                      </Markdown>
                    </Box>
                  ) : (
                    <Center height="full">
                      <Pxl m={0}>No details available</Pxl>
                    </Center>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Container maxW={"100%"}>
              <Flex justifyContent={"space-around"} alignItems={"center"}>
                <ButtonCircularIcon
                  style={{ cursor: "auto" }}
                  // disabled={true}
                  icon={`${getPriorityofNews(newsData?.priority)?.img}`}
                >
                  {`${getPriorityofNews(newsData?.priority)?.text} Priority`}
                </ButtonCircularIcon>
                <ButtonCircularIcon
                  icon={"/related_news.png"}
                  onClick={() => {
                    fetchRelatedNews();

                    relatedNewsModalDisclosure.onOpen();
                  }}
                >
                  Related News
                </ButtonCircularIcon>
                <ButtonCircularIcon
                  icon={"/related_notes.png"}
                  onClick={() => {
                    fetchRelatedNotes();

                    relatedNoteModalDisclosure.onOpen();
                  }}
                >
                  Related Notes
                </ButtonCircularIcon>
                <ButtonCircularIcon
                  onClick={() => {
                    console.log("ctxhead", newsData.title);
                    setForceOpen(true);
                  }}
                  icon={"/MG_Home.png"}
                >
                  Ask Master-G
                </ButtonCircularIcon>
              </Flex>

              <Box
                mt={"20px"}
                w={"100%"}
                zIndex={1}
                position={"relative"}
                bgColor={"white"}
                minH={"200px"}
                borderRadius={12}
                boxShadow={"0px 4px 10px 0px #0000001A"}
                border={"1px solid #B6B6B6"}
              >
                <Container ref={menuRef} maxW={"95%"}>
                  <Pmd pt={"20px"}>
                    <strong>{newsData.title}</strong>
                  </Pmd>
                  <Pmd>
                    <Markdown>{newsData.generated_summary}</Markdown>
                  </Pmd>
                  <Pmd>
                    Related link: <a href={newsData.link} style={{color:'#0962A2'}} target="_blank" rel="noopener noreferrer">{newsData.link}</a>
              </Pmd>
                </Container>
              </Box>
            </Container>
            <AskBudAccent
              notShowAccent={true}
              context={`Give answers related to ${selectedText} in the context of ${newsContent?.title}`}
              forceOpen={forceOpen}
              setForceOpen={setForceOpen}
              selectedText={selectedText}
              initText={newsData.title}
            />
            <CustomStyledModal
              force_rel={"news"}
              force_topic={newsData.title}
              force_date={newsData?.pub_date}
              toast={
                "Note successfully created! You can view it on the Notes page in this topic"
              }
              topicDetail={topicDetail}
              add_note
              area={newsData.area}
              force_id={newsData.news_id}
              isOpen={notesModalStates.isOpen}
              onOpen={notesModalStates.onOpen}
              onClose={notesModalStates.onClose}
              topic={newsData.topic}
              title={"Notes"}
              selectedText={selectedText}
            />
            <CustomStyledModal
              quick_exp={quickexpText}
              quick_explain
              isOpen={explanationModalStates.isOpen}
              onOpen={explanationModalStates.onOpen}
              onClose={explanationModalStates.onClose}
              title={"Quick Explanation"}
              selectedText={selectedText}
              isLoading={isExplainationLoading}
            />
            <Flex
              onClick={() => {
                notesModalStates.onOpen();
              }}
              cursor={"pointer"}
              w={"80px"}
              h={"80px"}
              alignItems={"center"}
              justifyContent={"center"}
              bg={"claydeck.brand.primary"}
              border={"2px solid white"}
              boxShadow={"0px 4px 4px 0px #00000040"}
              position={"fixed"}
              bottom={"120px"}
              zIndex={2}
              right={"20px"}
              borderRadius={"50px"}
            >
              <Text color={"white"} margin={0} fontSize={"32px"}>
                +
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </>
  );
};

export default NewsReading;
