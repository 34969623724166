import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {NEW_API_URL} from "../redux/types";
import {Box, Container, Flex, Spinner, useDisclosure} from "@chakra-ui/react";
import Markdown from "react-markdown";
import Pmd from "./Typography/Text/Pmd";
import ButtonText from "./Button/ButtonText";
import {HighlightMenu} from "react-highlight-menu";
import CustomStyledModal from "./Modal/CustomStyledModal";
import ButtonSecondary from "./Button/ButtonSecondary";
import ButtonPrimary from "./Button/ButtonPrimary";
import TimelineViz from "./TimelineViz";
import TestsQuiz from "../Pages/home/TestsQuiz";
import IToolsReading from "../Pages/home/IToolsReading";
import NewsReading from "../Pages/home/NewsReading";
import NotesReading from "../Pages/home/NotesReading";
import TimelineReading from "../Pages/home/TimelineReading";
import {useNavigate} from "react-router-dom";

function ConceptReader(props) {

    const {setActiveConcept,activeConcept,conceptDetail} = props;
    const [readerData,setReaderData] = useState(undefined);
    const navigate = useNavigate();

    const[nextState,setNextState] = useState(0)
    const[prevState,setPrevState] = useState(0)
    const[isFinish,setIsFinish] = useState(false)



    const getCurrentConceptData= () =>{
        const currentActiveConceptObject = conceptDetail.contents[activeConcept]
        console.log(currentActiveConceptObject,'currentActiveConceptObject');
        if(currentActiveConceptObject.type==='note'){
            axios
                .get(
                    NEW_API_URL +
                    "notes/get_note/admin@claydeck.com" +
                    "/" +
                    currentActiveConceptObject.id +
                    "/"
                )
                .then((r) => {
                    setReaderData(r.data)
                });
            return
        }
        if(currentActiveConceptObject.type==='timeline'){
            axios.get(NEW_API_URL+'lessonplan/timeline/'+currentActiveConceptObject.id+'/')
                .then((r) => {
                    setReaderData(r.data)
                });
            return
        }

        setReaderData(true)


    }



    useEffect(() => {
        console.log(activeConcept,conceptDetail,'aaaaee')
        if(conceptDetail && conceptDetail.contents)
        getCurrentConceptData()

    }, [activeConcept,conceptDetail]);



    const getNotesReader = () => {
        return <>
            <NotesReading isConceptReader noteid={conceptDetail.contents[activeConcept].id}/>
        </>
    }
    const getTimelineReader = () => {
        return <>
            <TimelineReading isConceptReader noteid={conceptDetail.contents[activeConcept].id}/>

        </>
    }

    const getQuizReader = () => {
        return <>
           <TestsQuiz next={nextState} prev={prevState} finish={setIsFinish} isConceptReader noteid={conceptDetail.contents[activeConcept].id}/>
        </>
    }

    const getItoolReader = () => {
        return <>
            <IToolsReading next={nextState} prev={prevState} finish={setIsFinish} isConceptReader noteid={conceptDetail.contents[activeConcept].id}/>
        </>
    }

    const getNewsReader = () => {
        return <>
            <NewsReading isConceptReader noteid={conceptDetail.contents[activeConcept].id}/>
        </>
    }


    const isRestricted=(concept) =>{
        if(concept.type ==='quiz'|| concept.type==='itool' || concept.type==='news' || concept.type==='timeline' || concept.type==='note' || concept.type==='map')
            return true;
        return false;
    }

    const isPagingType=(concept) =>{
        if(concept.type ==='quiz'|| concept.type==='itool')
            return true;
        return false;
    }

    const isLast = () => {
        console.log(activeConcept,conceptDetail.contents.length,'lekan')
        if(activeConcept===conceptDetail.contents.length-1){
            if(isPagingType(conceptDetail.contents[activeConcept])){
                return isFinish;
            }
            else {
                return true
            }
        }
    }



    if(!conceptDetail || !readerData)
        return <Spinner></Spinner>

    console.log(nextState,prevState,'paging')

    return (
        <Box>
            <Box h={'50vh'} overflowY={'auto'}>
            {
                conceptDetail.contents[activeConcept].type === 'note' && getNotesReader()
            }
            {
                conceptDetail.contents[activeConcept].type === 'timeline' && getTimelineReader()
            }
{
                conceptDetail.contents[activeConcept].type === 'quiz' && getQuizReader()
            }
            {
                conceptDetail.contents[activeConcept].type === 'itool' && getItoolReader()
            }
            {
                conceptDetail.contents[activeConcept].type === 'news' && getNewsReader()
            }
                {
                    conceptDetail.contents[activeConcept].type === 'map' && <>Place holder</>
                }
            </Box>

            <Flex
                alignItems={"center"}
                justifyContent={"space-around"}
                mt={"60px"}
            >
                <ButtonSecondary
                    isDisabled={activeConcept <= 0}
                    style={{margin: 10, width: '40%',position:'relative',zIndex:1}}

                    onClick={() => {
                        if(!isRestricted(conceptDetail.contents[activeConcept-1]) && !isRestricted(conceptDetail.contents[activeConcept])) {
                            setReaderData(undefined)
                        }
                        if(!isRestricted(conceptDetail.contents[activeConcept])){
                            setActiveConcept(activeConcept-1)
                        }
                        else {
                            if(Math.abs(prevState-nextState) === 0){
                                setActiveConcept(activeConcept-1)
                                if(!isRestricted(conceptDetail.contents[activeConcept-1]))
                                setReaderData(undefined)
                                setPrevState(0)
                                setNextState(0)
                            }
                            else {
                                setPrevState(prevState + 1)
                                setIsFinish(false)
                            }
                        }
                    }}
                >
                    Previous
                </ButtonSecondary>

                <ButtonPrimary
                    style={{margin: 10, width: '40%',position:'relative',zIndex:1}}

                    // isDisabled={activeConcept }
                    onClick={() => {
                        if(isLast()){
                            navigate(-1)
                            return
                        }
                        // console.log(conceptDetail.contents[activeConcept+1].type)

                        // if(activeConcept === conceptDetail.contents.length-1){
                        //
                        // }
                        //

                        //
                        // if((!isRestricted(conceptDetail.contents[activeConcept+1])) && (!isRestricted(conceptDetail.contents[activeConcept]))) {
                        //     setReaderData(undefined)
                        // }

                        if(isFinish){
                            // if(!isRestricted(conceptDetail.contents[activeConcept+1])){
                            //     setReaderData(undefined)
                            // }
                            setActiveConcept(activeConcept+1)
                            setIsFinish(false)
                            setPrevState(0)
                            setNextState(0)
                            return
                        }
                        // console.log(isPagingType(conceptDetail.contents[activeConcept]))

                        if(!isRestricted(conceptDetail.contents[activeConcept])){
                            setActiveConcept(activeConcept+1)
                        }
                        else {
                            if(isPagingType(conceptDetail.contents[activeConcept])){
                                setNextState(nextState+1)
                            }
                            else {
                                setActiveConcept(activeConcept+1)
                            }
                        }
                    }}
                >
                    {isLast()?'Finish' : 'Next'}
                </ButtonPrimary>
            </Flex>


        </Box>
    );
}

export default ConceptReader;