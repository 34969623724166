import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import {Box, Flex, Spinner} from "@chakra-ui/react";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar/Sidebar";

import { useSelector } from "react-redux";
import DoubleSidebar from "../Components/DoubleSidebar";

const HeaderLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const token = useSelector((state) => state.user.accessToken);
  const loginLoading = useSelector(state => state.UI.loginLoading);

  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    if (pathname.includes("/password-reset")) return; // added this condition such that user can visit password reset, logged in or not

    if (token === null || !token) {
      if (!isAuthPath())
        // if user is not logged in
        navigate("/signin");
    } else if (isAuthPath()) {
      // if user is valid
      navigate("/home");
    }
  }, [token, location]);

  const isAuthPath = () => {
    // check if the current path is a part of auth routes or not
    const commonPaths = [
      "/password-reset",
      "/plans",
      "/join-workspace",
      "/join-feed",
    ];
    let authPaths = [
      "/signup",
      "/signin",
      "/survey-questions",
    ];

    if (token === null) {
      // user should not get redirected to signup when they are logged in and visit a common path
      // run only when user is not logged in
      authPaths = [...authPaths, ...commonPaths];
    }

    const result = authPaths.find(path => pathname.includes(path) && path.length >= pathname.length);
    return result ? true : false;
  }

  if (loginLoading) {
    return (
      <Flex height={"100vh"} justify={"center"} align={"center"}>
        <Spinner size={"lg"} color="claydeck.brand.secondary" />
      </Flex>
    );
  }

  const routes_for_disable_sidebar = ['/']
  // const routes_for_enable_double_sidebar = ['learn']
  // console.log(pathname)




  return (
    <>
      {token !== null && token ? (
        <>
          <header>
            { !routes_for_disable_sidebar.includes(pathname) && <Navbar
                setSidebarVisible={() => setSidebarVisible((prev) => !prev)}
            />}
          </header>
          <Flex wrap={sidebarVisible?'wrap':'nowrap'} bgColor={"claydeck.white.secondary"}>
            {!routes_for_disable_sidebar.includes(pathname) &&<Sidebar sidebarVisible={sidebarVisible}/>}
            {window.location.href.includes('learn/') && <><DoubleSidebar/>
              <Box mr={'6%'}/></>}
            <Outlet />
          </Flex>
        </>
      ) : (
        <Outlet />
      )}


      {/*<>*/}
      {/*  <header>*/}
      {/*    <Navbar*/}
      {/*        setSidebarVisible={() => setSidebarVisible((prev) => !prev)}*/}
      {/*    />*/}
      {/*  </header>*/}
      {/*  <Flex wrap={sidebarVisible?'wrap':'nowrap'} bgColor={"claydeck.white.secondary"}>*/}
      {/*    <Sidebar sidebarVisible={sidebarVisible} />*/}
      {/*    <Outlet />*/}
      {/*  </Flex>*/}
      {/*</>*/}

    </>
  );
};

export default HeaderLayout;
