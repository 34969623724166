import React, {useEffect, useState} from 'react';
import {Box, Flex, Image, StackDivider, VStack} from "@chakra-ui/react";
import MultiLayerDropdown from "./MultiLayerDropdown";
import axios from "axios";
import {NEW_API_URL} from "../redux/types";
import {useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import Psm from "./Typography/Text/Psm";

function DoubleSidebar(props) {
    const { topic, subject, area } = useParams();
    const navigate = useNavigate();

    const [subjectData, setSubjectData] = useState([]);
    const username = useSelector((state) => state.user.username);
    let workspaceId = 1;

    const get_lesson_map_data = async (username, workspace) => {
        const r = await axios.get(
            NEW_API_URL +
            "lessonplan/get_lessonplan/" +
            username +
            "/" +
            workspace +
            "/"
        );
        console.log(r);
        setSubjectData(r.data);
        // return r.data
    };


    useEffect(() => {
        get_lesson_map_data(username, workspaceId);
    }, []);

    const routeData = [
        {
            'title':'Learn',
            'img':'/detail_reading.png',
            'path':'/readings'
        },{
            'img':'/detail_notes.png',
            'path':'/notes'
        },{
            'title':'Master',
            'path':'/timeline',
            'img':'/detail_map.png'
        },{
            'img':'/itools.png',
            'path':'/itools'
        },{
            'title':'Apply',
            'path':'/news',

            'img':'/detail_news.png'
        },{
            'path':'/tests',
            'img':'/detail_test.png'
        },
    ]

    return (
        <Box  left={'6%'} position={'fixed'} top={'43%'} h={'90vh'} mt={'-36vh'} w={'6%'} zIndex={100}>
        {/*<Flex alignItems="center" justifyContent="center" >*/}
        <Box borderRadius={' 0px'} zIndex={100} bgColor={'#1E5F85'} h={'100vh'} w={'100%'}>

            <Flex alignItems={"center"} justifyContent={"center"} >
                <VStack
                    paddingTop={'2em'}
                    // divider={<StackDivider borderColor='gray.200' />}
                    spacing={4}
                    align='center'
                >
                    {/* <Box style={{paddingLeft:'2rem',paddingRight:'2rem',zIndex:999,position:'relative'}}>
                    <MultiLayerDropdown style={{color:'white',border:'1px solid white'}} onSelect={(e)=>{

                        // setSubjectSelect(e)
                        console.log(e)
                        navigate("/learn/" +
                            e.subject.subject_id +
                            "/" +
                            e.area.area_id +
                            "/" +
                            e.topic.topic_id)
                    }} data={subjectData} text={"Change Topic"} />
                    </Box> */}
                    {routeData.map((item, index) => (<>
                            {item.title && <Psm m={0} color={'white'}>{item.title}</Psm>}

                            <Link to={"/learn/" +
                            subject +
                            "/" +
                            area +
                            "/" +
                            topic +
                            ""+item.path}>
                        <Box cursor={'pointer'} key={index} borderRadius={'30px'} mx={2}>
                            <Image w={'4rem'} src={item.img}/>
                        </Box>
                        </Link>
                        </>
                    ))}
                </VStack>
            </Flex>


        </Box>
        {/*</Flex>*/}
        </Box>
    );
}

export default DoubleSidebar;
