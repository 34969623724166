import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imagePath from "../constants/imagePath";
import { featureFlags } from "../featureflags";

const MultiLayerDropdown = ({ data, text, pageTitle,onSelect,style }) => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [subjectData,setSubjectData] = useState(data);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [expandedSubjectId, setExpandedSubjectId] = useState(null);
  const [expandedAreaId, setExpandedAreaId] = useState(null);
  const navigate = useNavigate();

  const enhanceSubjectData = (subjectData) => {
    const subjectEnhancements = {
      Polity: {
        top: "43%",
        left: "5%",
        type: "right",
        ry: -40,
        sy: -24.5,
        img: imagePath.polity,
      },
      History: {
        top: "59%",
        left: "39%",
        type: "left",
        ry: -40,
        sy: 24.5,
        img: imagePath.historymap,
      },
      Economics: {
        top: "7%",
        left: "65%",
        type: "left",
        ry: -40,
        sy: 24.5,
        img: imagePath.economy,
      },
      Geography: {
        top: "45%",
        left: "65%",
        type: "left",
        ry: -40,
        sy: 24.5,
        img: imagePath.geography,
      },
      Environment: {
        top: "7%",
        left: "5%",
        type: "right",
        ry: -40,
        sy: -24.5,
        img: imagePath.environment,
      },
      Science: {
        top: "25%",
        left: "30%",
        type: "right",
        ry: -40,
        sy: -24.5,
        img: imagePath.science,
      },
    };

    return subjectData.map((subject) => ({
      ...subject,
      ...subjectEnhancements[subject.subject_name],
    }));
  };

  useEffect(() => {
    setSubjectData(enhanceSubjectData(data));
  }, [data]);

  const handleSubjectClick = (subject) => {
    setExpandedSubjectId(
      subject.subject_id === expandedSubjectId ? null : subject.subject_id
    );
    setSelectedSubject(subject);
    setSelectedArea(null); // Reset area selection when switching subjects
  };

  const handleAreaClick = (area) => {
    setExpandedAreaId(area.area_id === expandedAreaId ? null : area.area_id);
    setSelectedArea(area);
  };
  const handleTopicClick = (subject, area, topic) => {
    setSelectedSubject(subject);
    setSelectedArea(area);
    setSelectedTopic(topic);

    // Redirect to the topic page
    if(onSelect){
      onSelect({subject,area,topic})
    }
    else {
      window.location.href = featureFlags.conceptDetailsPage ? `/learn/${subject.subject_id}/${area.area_id}/${topic.topic_id}/concepts` : `/learn/${subject.subject_id}/${area.area_id}/${topic.topic_id}`;
    }
    // navigate(`/learn/${subject.subject_id}/${area.area_id}/${topic.topic_id}`)
  };

  return (
    <div>
      <div className="dropdown">
        <button className="dropdown-button" style={{...style}}>{text}</button>
        <div className="dropdown-content">
          {subjectData.map((subject) => (
            <div key={subject.subject_id}>
              <div
                className={`dropdown-item ${
                  expandedSubjectId === subject.subject_id
                    ? "dropdown-item-selected"
                    : ""
                }`}
                style={{display:'flex', alignItems:'center'}}
                onClick={() => handleSubjectClick(subject)}
              >
                <img src={subject.img} style={{width:'45px', height:'45px'}} alt={subject.subject_name} />
                <span>
                  {subject.subject_name} ({Math.round(subject.completion_rate)}
                  %)
                </span>
              </div>
              {expandedSubjectId === subject.subject_id && (
                <div className="submenu">
                  {subject.areas.map((area) => (
                    <div key={area.area_id}>
                      <div
                        className={`dropdown-item ${
                          expandedAreaId === area.area_id
                            ? "dropdown-item-selected"
                            : ""
                        }`}
                        onClick={() => handleAreaClick(area)}
                        // style={{display:'flex', alignItems:'center'}}
                      >
                        <img src={area.area_image} style={{width:'45px', height:'45px', borderRadius:'12px'}} alt={area.area_name} />
                        <span>
                          {area.area_name} ({Math.round(area.completion_rate)}%)
                        </span>
                      </div>
                      {expandedAreaId === area.area_id && (
                        <div className="submenu">
                          {area.topics.map((topic) => (
                            <div
                              key={topic.topic_id}
                              onClick={() =>
                                handleTopicClick(subject, area, topic)
                              }
                              className="dropdown-item"
                            >
                              {/* <img src={topic.topic_image} style={{width:'45px', height:'45px', borderRadius:'12px'}} alt={area.area_name} /> */}
                              {topic.topic_name} (
                              {Math.round(topic.completion_rate)}%)
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        .dropdown {
          position: relative;
          display: inline-block;
        }

        .dropdown-button {
          display: ${pageTitle === "Learn" ? "none" : "block"};
          padding: 10px;
          font-size: 16px;
          cursor: pointer;
          color: #0962a2;
          border-radius: 16px;
          border: 1px solid #0962a2;
        }

        .dropdown-content {
          display: ${pageTitle === "Learn" ? "block" : "none"};
          position: absolute;
          background-color: #0962a2;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          padding: 24px;
          z-index: 1;
          border-radius: 16px;
          width: 300px;
          height: '70vh';
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }

        .dropdown-item {
          padding: 8px;
          padding-top: 24px;
          padding-bottom: 24px;
          cursor: pointer;
          position: relative;
          color: white;
          border-radius: 16px;
          gap: 8px;
        }

        .dropdown-item-selected {
          background-color: #f9f9f9;
          color: #0962a2;
        }

        .dropdown-item:hover {
          background-color: #f9f9f9;
        }

        .submenu {
          display: block;
          position: absolute;
          left: 100%; /* Position to the right of parent */
          top: 0;
          background-color: #0962a2;
          padding: 24px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          min-width: 350px; /* Ensures submenu is visible */
          z-index: 1;
          border-radius: 16px;
          gap: 8px;
        }

        .dropdown-item:hover > .submenu {
          display: block;
        }
      `}</style>
    </div>
  );
};

export default MultiLayerDropdown;
