import React, { useState, useEffect, useRef } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  Textarea,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import Markdown from "react-markdown";
import { HighlightMenu, setClipboard } from "react-highlight-menu";
import ButtonText from "../../Components/Button/ButtonText";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import AskBudAccent from "../../Components/AskBudAccent";
import BackArrow from "../../Components/backArrow";

const NotesReading = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedText, setSelectedText] = useState("");
  const [quickexpText, setQuickExpText] = useState("");
  const [isExplainationLoading, setIsExplainationLoading] = useState(false);
  const [concepts, setConcepts] = useState([]);
  const [notesTextData, setNotesTextData] = useState({});
  const menuRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const notesModalStates = useDisclosure();
  const { topic, subject, area, noteid, indext } = useParams();
  const mynote = searchParams.get("mynote"); // 'myParam' is the key of the query param

  const newUser = useSelector((state) => state.user.newUser);
  const username = useSelector((state) => state.user.username);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const user = useSelector((state) => state.user);
  let workspaceId = "";
  if (selectedWorkspace && selectedWorkspace.hasOwnProperty("workspace_id")) {
    workspaceId = selectedWorkspace.workspace_id;
  }
  const [topicDetail, setTopicDetail] = useState({});

  const get_map_data = async (username, workspace) => {
    const r = await axios
      .get(
        NEW_API_URL +
          "lessonplan/get_topic_detail/" +
          username +
          "/" +
          workspace +
          "/"
      )
      .catch((err) => {});
    // setTopicDetail(r.data.topic_detail)
    // setDailyNews(r.data.topic_detail.news)
    setTopicDetail(r?.data?.topic_detail);

    return r?.data;
  };

  useEffect(() => {
    get_map_data(username, topic);
  }, []);

  useEffect(() => {
    if (props.props || noteid)
      axios
        .post(NEW_API_URL + "lessonplan/update_activity/", {
          user_id: username,
          topic_id: topic,
          activity_type: "concept",
          activity_id: props.noteid || noteid,
          read_status: "Read",
        })
        .then((r) => {});
  }, []);

  useEffect(() => {
    if (indext) get_topic_detail();
  }, [indext]);

  const get_topic_detail = async () => {
    let cp = concepts;
    if (concepts.length <= 0) {
      if (topic === "mynote") {
        const r = await axios.get(
          NEW_API_URL + "notes/get_note_list/" + username
        );
        const arr = r.data;
        setMaxPages(r.data.length);
        setConcepts(arr);
        cp = arr;
      } else {
        const r = await axios
          .get(
            NEW_API_URL +
              "lessonplan/get_topic_detail/" +
              username +
              "/" +
              topic +
              "/"
          )
          .catch((err) => {});
        const arr = r.data.topic_detail.concepts;
        setMaxPages(r.data.topic_detail.concepts.length);
        setConcepts(arr);
        cp = arr;
      }
    }

    if (indext < cp.length) {
      axios
        .get(
          NEW_API_URL +
            "notes/get_note/" +
            (mynote === "1" ? username : "admin@claydeck.com") +
            "/" +
            cp[indext].id +
            "/"
        )
        .then((r) => {
          setNotesTextData(r.data);
          setNoteDetail({
            pages: [r.data.description],
            qa: [],
          });
          // setMa
        })
        .catch((err) => {});

      axios
        .post(NEW_API_URL + "lessonplan/update_activity/", {
          user_id: username,
          topic_id: topic,
          activity_type: "concept",
          activity_id: cp[indext].id,
          read_status: "Read",
        })
        .then((r) => {})
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (props.noteid || noteid)
      axios
        .get(
          NEW_API_URL +
            "notes/get_note/" +
            (mynote === "1" ? username : "admin@claydeck.com") +
            "/" +
            (props.noteid || noteid) +
            "/"
        )
        .then((r) => {
          setNotesTextData(r.data);
          setNoteDetail({
            pages: [r.data.description],
            qa: [],
          });
          // setMa
        });
    if (indext) {
      get_topic_detail();
    }
  }, []);

  const [page, setPage] = useState(0);
  const [forceOpen, setForceOpen] = useState(false);
  const [initText, setInitText] = useState("");
  const [maxPages, setMaxPages] = useState(0);
  const [adminUser, setAdminUser] = useState(false);
  const [resize, setResize] = useState("horizontal");

  // const [noteDetail, setNoteDetail] = useState({
  //   pages:['PG1'+markdown_str,'PG2'+markdown_str,'PG3'+markdown_str],
  //   qa:[{question:'aaaa',answer:'test'},{question:'bbbb',answer:'aa'}]
  // });

  const [noteDetail, setNoteDetail] = useState({ pages: [], qa: [] });

  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"90%"}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex mt={props.isConceptReader ? "0" :"10vh"} alignItems={"center"}>
              {/* <ArrowBackIcon
                cursor={"pointer"}
                onClick={() => {
                  navigate(-1, { replace: true });
                }}
                marginRight={"20px"}
                boxSize={"45px"}
              /> */}
                {!props.isConceptReader && <BackArrow
                    onClick={() => {
                        navigate(-1, {replace: true});
                    }}
                />}

              <Box>
                <P2xl mb={0} ml={0} mr={0}>
                  <strong>{notesTextData.title}</strong>
                </P2xl>
                <Pmd color={"#817D7D"} style={{ margin: 0 }}>
                  <strong>{notesTextData.topic}</strong>
                </Pmd>
              </Box>
            </Flex>
            <hr />

            <Container
              borderRadius={12}
              maxW={"container.xl"}
              ref={menuRef}
              backgroundColor={"white"}
              border={"1px solid #B6B6B6"}
              boxShadow={"0px 4px 4px 0px #00000040"}
              height={"60vh"}
              overflow={"auto"}
              p={12}
            >
              {noteDetail.pages.length > page && (
                <>
                  <Markdown>{noteDetail.pages[page]}</Markdown>

                  {notesTextData.attachments.images.length > 0 && (
                    <Flex flexDirection={"column"}>
                      <Pmd color={"#817D7D"} style={{ marginTop: 16 }}>
                        Related Images:
                      </Pmd>
                      {notesTextData.attachments.images.map((link) => {
                        return (
                          <img
                            src={link}
                            style={{
                              maxWidth: "150px",
                              border: "1px solid",
                              borderRadius: "16px",
                            }}
                          />
                        );
                      })}
                    </Flex>
                  )}

                  {notesTextData.attachments.links.length > 0 && (
                    <Flex flexDirection={"column"}>
                      <Pmd color={"#817D7D"} style={{ marginTop: 16 }}>
                        Related Links:
                      </Pmd>
                      {notesTextData.attachments.links.map((link) => {
                        return (
                          <a href={link} target="_blank">
                            {link}
                          </a>
                        );
                      })}
                    </Flex>
                  )}

                  {notesTextData.attachments.related_items.length > 0 && (
                    <Flex flexDirection={"column"}>
                      <Pmd color={"#817D7D"} style={{ marginTop: 16 }}>
                        Related Items:
                      </Pmd>
                      {notesTextData.attachments.related_items.map(
                        (related_item, index) => {
                          return (
                            <Box>
                              <Pmd style={{ margin: 0 }}>
                                {index + 1}. {related_item.type}:{" "}
                                {related_item.title}
                              </Pmd>
                            </Box>
                          );
                        }
                      )}
                    </Flex>
                  )}
                </>
              )}
            </Container>
            {adminUser && (
              <>
                <Textarea
                  placeholder=""
                  size="lg"
                  resize={resize}
                  onChange={(e) => {
                    let cpy = JSON.parse(JSON.stringify(noteDetail));
                    // console.log(cpy,e.target.value)
                    cpy["pages"][page] = e.target.value;
                    setNoteDetail(cpy);
                  }}
                  mb={6}
                  px={20}
                  value={noteDetail.pages[page]}
                  sx={{
                    "field-sizing": "content",
                    height: "initial",
                  }}
                />
                <ButtonPrimary
                  onClick={() => {
                    axios
                      .post(NEW_API_URL + "notes/update_note/", {
                        id: props.noteid || noteid,
                        description: noteDetail.pages[page],
                      })
                      .then((r) => {
                        console.log(r);
                      });
                  }}
                >
                  <strong>Save</strong>
                </ButtonPrimary>
              </>
            )}

            <Center>
              {indext && (
                <ButtonSecondary
                  isDisabled={indext <= 0}
                  style={{ marginTop: 32, width: "30vw", marginRight: 16 }}
                  onClick={() => {
                    navigate(
                      `/learn/${subject}/${area}/${topic}/notes_reading/${
                        parseInt(indext) - 1
                      }${mynote === "1" ? "?mynote=1" : ""}`,
                      { replace: true }
                    );

                    // setPage(page-1)
                  }}
                >
                  {"Previous"}
                </ButtonSecondary>
              )}

              {indext < maxPages - 1 && (
                <ButtonPrimary
                  style={{ marginTop: 32, width: "30vw" }}
                  onClick={() => {
                    navigate(
                      `/learn/${subject}/${area}/${topic}/notes_reading/${
                        parseInt(indext) + 1
                      }${mynote === "1" ? "?mynote=1" : ""}`,
                      { replace: true }
                    );
                    // setPage(page + 1)
                  }}
                >
                  Next
                </ButtonPrimary>
              )}
              {page === maxPages && searchParams.get("home") && (
                <ButtonPrimary link={"/home"} style={{ margin: 10 }}>
                  Go to Home
                </ButtonPrimary>
              )}
            </Center>

            <Center mb={props.isConceptReader?0:20} mt={18}>
              {Array(maxPages)
                .fill(0)
                .map((item, index) => {
                  return (
                    <>
                      <Box
                        onClick={() => {
                          navigate(
                            `/learn/${subject}/${area}/${topic}/notes_reading/${index}${
                              mynote === "1" ? "?mynote=1" : ""
                            }`,
                            { replace: true }
                          );
                        }}
                        cursor={"pointer"}
                        mr={"12px"}
                        borderRadius={50}
                        w={"12px"}
                        h={"12px"}
                        backgroundColor={
                          index === parseInt(indext) ? "#0962A2" : "#A2A2A24D"
                        }
                      ></Box>
                    </>
                  );
                })}
            </Center>
          </Box>
        </Flex>

        <HighlightMenu
          styles={{
            backgroundColor: "#344675",
            borderRadius: "16px",
          }}
          menu={({ selectedText, setMenuOpen, setClipboard }) => {
            return (
              <Flex bg={"#344675"} padding={0} borderRadius={"30px"}>
                <ButtonText
                  fontWeight={"300"}
                  onClick={() => {
                    setQuickExpText("");
                    setSelectedText(selectedText);
                    onOpen();
                    setMenuOpen(false);
                    const payload = {
                      action: "doubt",
                      phrase: selectedText,
                      context:
                        "You are context giver bot for explaining the phrase or word",
                    };
                    setIsExplainationLoading(true);
                    axios
                      .post(
                        NEW_API_URL +
                          "gamification/instant_doubt_clarification/",
                        payload
                      )
                      .then((r) => {
                        setQuickExpText(r.data.message);
                        setIsExplainationLoading(false);
                        // setChat((prev)=>[...prev,{'from':'bud',text:r.data.message,time:new Date().toISOString()}])
                      });
                  }}
                >
                  Quick Explanation
                </ButtonText>
                <Box border={"0.5px solid white"} h={"100%"} />

                <ButtonText
                  fontWeight={"300"}
                  onClick={() => {
                    setForceOpen(true);
                    setInitText(
                      "You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " +
                        selectedText
                    );
                    setSelectedText(selectedText);
                    // notesModalStates.onOpen()
                    setMenuOpen(false);
                  }}
                >
                  Ask Question
                </ButtonText>
                <Box border={"0.5px solid white"} h={"100%"} />
                <ButtonText
                  fontWeight={"300"}
                  onClick={() => {
                    setSelectedText(selectedText);
                    notesModalStates.onOpen();
                    setMenuOpen(false);
                  }}
                >
                  Add to Note
                </ButtonText>
              </Flex>
            );
          }}
          target={menuRef}
        />
        {/*      <div ref={menuRef}>*/}
        {/*  Selecting this text will show the menu!*/}
        {/*</div>*/}

        <CustomStyledModal
          quick_exp={quickexpText}
          quick_explain
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          title={"Quick Explanation"}
          selectedText={selectedText}
          isLoading={isExplainationLoading}
        />
        <CustomStyledModal
          add_type_topic={true}
          force_rel={"note"}
          force_topic={notesTextData.topic}

          topicDetail={topicDetail}
          area={notesTextData.area}
          subject={notesTextData.subject}
          topic_name={notesTextData.topic}
          topic_id={topic}
          toast={
            "Note successfully created! You can view it on the Notes page in this topic"
          }
          add_note
          isOpen={notesModalStates.isOpen}
          onOpen={notesModalStates.onOpen}
          onClose={notesModalStates.onClose}
          title={"Notes"}
          selectedText={selectedText}
        />
        <AskBudAccent
          context={`Give answers related to ${selectedText} in the context of ${notesTextData.topic}`}
          forceOpen={forceOpen}
          selectedText={selectedText}
          setForceOpen={setForceOpen}
          initText={notesTextData?.title}
        />
      </Container>
    </>
  );
};

export default NotesReading;
