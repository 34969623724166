import React, {useEffect, useRef} from 'react';
import {
    Box,
    Stack,
    Step,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus
} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";

function CustomStepper(props) {
    const {conceptDetail,activeConcept} = props;
    const scrollableContainerRef = useRef(null);
    const cardToScrollRef = useRef(null);
    const scrollToCard = () => {
        console.log(cardToScrollRef)
        if (cardToScrollRef.current) {
            cardToScrollRef.current.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });

            // Option 2: Use scrollTo with offset (if needed)
            // const container = scrollableContainerRef.current;
            // const card = cardToScrollRef.current;
            // if (container && card) {
            //   const offsetLeft = card.offsetLeft - container.offsetLeft;
            //   container.scrollTo({
            //     left: offsetLeft,
            //     behavior: "smooth",
            //   });
            // }
        }
    };


    useEffect(() => {
        setTimeout(() => {
            scrollToCard()

        }, 500)
    }, []);

    return (
        <Box w={'100%'} overflowX={'auto'} mb={'15px'}>
            <Box ref={scrollableContainerRef} position={'relative'}
                 width={conceptDetail?.contents.length * 200 + 'px'}>
                {/*<Button onClick={scrollToCard}>aa</Button>*/}
                <Stepper index={activeConcept}>
                    {conceptDetail?.contents.map((step, index) => (
                        <Step  ref={index === activeConcept ? cardToScrollRef : null} key={index}>
                            <Stack cursor={'pointer'} onClick={()=>{
                                props.setActiveConcept(index)
                            }} alignItems={'center'} spacing={1}>
                                <Box>

                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon/>}
                                            incomplete={<StepNumber/>}
                                            active={<StepNumber/>}
                                        />
                                    </StepIndicator>

                                </Box>
                                <Box textAlign={'center'}>
                                    <Pmd textAlign={'center'} fontWeight="bold" whiteSpace="normal">
                                        {step.type}
                                    </Pmd>
                                </Box>

                            </Stack>

                            <StepSeparator/>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Box>
    );
}

export default CustomStepper;