import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import {
    Avatar,
    Box,
    Button,
    Center,
    Container,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Select,
    Tag,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem,
    Text,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate, useParams } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import EmptyImage from "../../Components/EmptyImage";
import BackArrow from "../../Components/backArrow";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
import {CircularProgressbar} from "react-circular-progressbar";
import conceptData from '../../../src/data/dummyNewsTopicDetail.json'
import Plg from "../../Components/Typography/Text/Plg";

const NewsTopicDetail = (props) => {
    const { isTimeline } = props;
    console.log(isTimeline, "is_timeline_thing");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { topic, subject, area } = useParams();
    console.log("topic:", topic);
    const notesModalStates = useDisclosure();

    const newUser = useSelector((state) => state.user.newUser);
    const username = useSelector((state) => state.user.username);
    const selectedWorkspace = useSelector(
        (state) => state.user.selectedWorkspace
    );
    const user = useSelector((state) => state.user);
    let workspaceId = "";
    if (selectedWorkspace && selectedWorkspace.hasOwnProperty("workspace_id")) {
        workspaceId = selectedWorkspace.workspace_id;
    }
    console.log("workspace_ID timeline", workspaceId, selectedWorkspace, user);

    const [noteType, setNoteType] = useState("All Notes");
    const [topicDetail, setTopicDetail] = useState({});
    const [timelinesDetail, setTimelinesDetail] = useState([]);
    const [notesDetail, setNotesDetail] = useState([]);
    const [notesDetailOrigial, setNotesDetailOriginal] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [subjectData, setSubjectData] = useState([]);




    const get_lesson_map_data = async (username, workspace) => {
        const r = await axios.get(
            NEW_API_URL +
            "lessonplan/get_lessonplan/" +
            username +
            "/" +
            workspace +
            "/"
        );
        console.log(r);
        setSubjectData(r.data);
        // return r.data
    };

    useEffect(() => {
        get_lesson_map_data(username, 1);
    }, [subject,area,topic]);


    const get_map_data = async (username, workspace) => {
        setIsLoading(true);
        // const r = await axios.get(
        //     NEW_API_URL +
        //     "lessonplan/get_topic_detail/" +
        //     username +
        //     "/" +
        //     workspace +
        //     "/"
        // ).catch(err=>{});
        // setTopicDetail(r.data.topic_detail)
        // setDailyNews(r.data.topic_detail.news)

        console.log(conceptData,'concept data')
        setTopicDetail(conceptData)

        // if (isTimeline) {
        //   setTimelinesDetail(r.data.topic_detail.timelines);
        // } else {
        //   // notesDetailOrigial.concat(...topicDetail.related_notes)
        //   setNotesDetail(
        //     r.data.topic_detail.concepts
        //   );
        //   setNotesDetailOriginal(
        //     r.data.topic_detail.concepts
        //   );
        // }
        // setTopicDetail(r.data.topic_detail);
        // setIsLoading(false);

        // return r.data;
    };

    useEffect(() => {
        get_map_data(username, topic);
    }, []);

    return (
        <>
            <Container
                justifyContent={"center"}
                alignItems={"center"}
                w={"90%"}
                maxW="container.xl"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"space-between"}
                    justifyContent={"space-between"}
                    minH={"95vh"}
                >
                    <Box>
                        <Flex mt={"10vh"} alignItems={"center"}>
                            {/* <ArrowBackIcon
                cursor={"pointer"}
                onClick={() => {
                  navigate(-1);
                }}
                marginRight={"20px"}
                boxSize={"45px"}
              /> */}
                            <BackArrow />

                            <Box flex={1}>
                                <P2xl mb={0} ml={0} mr={0}>
                                    <strong>{topicDetail.title}</strong>
                                </P2xl>

                            </Box>
                            {/*<div style={{width: 50, height: 50,marginRight:10}}>*/}
                            {/*    <CircularProgressbar value={topicDetail.completion_rate} text={`${Math.round(topicDetail.completion_rate,0)}%`}/>*/}

                            {/*</div>*/}

                            {/*{topicDetail.priority === 'Low' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#5F79D7'} minW={'50px'}*/}
                            {/*                                           minH={'50px'} color={'white'}>{topicDetail.priority}</Center>}*/}
                            {/*{topicDetail.priority === 'Medium' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#F4B400'} minW={'50px'}*/}
                            {/*                                              minH={'50px'} color={'white'}>{topicDetail.priority}</Center>}*/}
                            {/*{topicDetail.priority === 'High' && <Center px={'5px'} borderRadius={'50px'} backgroundColor={'#FF0E36'} minW={'50px'}*/}
                            {/*                                            minH={'50px'} color={'white'}>{topicDetail.priority}</Center>}*/}

                            {/*<Box ml={"60px"} mt={"16px"}>*/}
                            {/*  <MultiLayerDropdown data={subjectData} style={{zIndex:900}} text={"Change Topic"} />*/}
                            {/*</Box>*/}
                            {/*<Pmd color={"#817D7D"} style={{ margin: 0 }}>*/}
                            {/*  <strong>History</strong>*/}
                            {/*</Pmd>*/}
                        </Flex>
                        <hr />


                        <HeadCard title={'Description'}>
                            <Box padding={'20px'}>
                                {topicDetail.description}

                            </Box>
                            <Box px={'20px'} pb={'20px'}>
                                <Pmd style={{color:'#0962A2'}}>Read more ..</Pmd>

                            </Box>
                        </HeadCard>

                        <Flex justifyContent={'space-between'} mt={'24px'}>
                            <HeadCard w={'47%'} style={{borderLeft:'2px solid #0962A2'}}>
                                <Flex>
                                <Image src={'/detail_test.png'} h={'100px'}></Image>
                                    <Box>
                                        <Plg><strong>Questions</strong></Plg>
                                        <Psm style={{color:'#4D4B4B'}}>Test yourself with UPSC style questions</Psm>
                                    </Box>
                                </Flex>
                            </HeadCard>
                            <HeadCard w={'47%'} style={{borderLeft:'2px solid #0962A2'}}>
                                <Flex>
                                    <Image src={'/detail_map.png'} h={'100px'}></Image>
                                    <Box>
                                        <Plg><strong>Timeline</strong></Plg>
                                        <Psm style={{color:'#4D4B4B'}}>See the events related to this topic</Psm>
                                    </Box>
                                </Flex>
                            </HeadCard>
                        </Flex>


                        <Flex mt={20} mb={8} justifyContent={"space-between"}>
                            {/*<Pmd>*/}
                            {/*    Select a concept below to start*/}
                            {/*</Pmd>*/}
                            <Box>

                                {/*<ButtonPrimary*/}
                                {/*    onClick={() => {*/}
                                {/*        window.location.href = "/learn/" +*/}
                                {/*            subject +*/}
                                {/*            "/" +*/}
                                {/*            area +*/}
                                {/*            "/" +*/}
                                {/*            topic +*/}
                                {/*            "/"*/}
                                {/*        // setNoteType("All Notes");*/}
                                {/*        // setNotesDetail(notesDetailOrigial);*/}
                                {/*    }}*/}
                                {/*    bg={*/}
                                {/*        noteType === "All Notes"*/}
                                {/*            ? "claydeck.brand.primary"*/}
                                {/*            : "transparent"*/}
                                {/*    }*/}
                                {/*    mr={"15px"}*/}
                                {/*    color={noteType === "All Notes" ? "white" : "#0962A2"}*/}
                                {/*>*/}
                                {/*    All Materials <Center ml={'10px'} bg={'white'} borderRadius={'36px'} w={'20px'} h={'20px'}><Image  h={'20px'} src={'/arrowright.png'}></Image></Center>*/}
                                {/*</ButtonPrimary>*/}
                            </Box>

                        </Flex>
                        <Wrap width={'100%'} spacing={"30px"} >


                            {topicDetail && topicDetail?.contents && topicDetail?.contents.map((item) => (
                                <WrapItem>
                                    <NotesCard
                                        mynote={(noteType === "My Notes")}
                                        read_status={item.read_status}
                                        key={item.id}
                                        to={
                                            "/learn/" +
                                            subject +
                                            "/" +
                                            area +
                                            "/" +
                                            topic +
                                            "/concepts/" +
                                            item.id
                                            +"?mynote="+((noteType === "My Notes")?1:0)
                                        }
                                        shadow
                                        w={"180px"}
                                        title={item.title}
                                        icon={item.icon_image_link}
                                    />
                                </WrapItem>
                            ))}
                            {/*<WrapItem>*/}
                            {/*<NotesCard to={isTimeline?'/learn/history/geo/eco/timeline/123':'/learn/history/geo/eco/notes/123'} shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Case study'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Mind map'} icon={'/mind_map_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                            {/*<WrapItem>*/}
                            {/*  <NotesCard shadow w={'180px'} title={'Notes 1'} icon={'/notes_preview.png'}/>*/}
                            {/*</WrapItem>*/}
                        </Wrap>
                    </Box>
                </Flex>
                <CustomStyledModal
                    add_type_topic={!isTimeline}
                    area={""}
                    subject={""}
                    topic_name={topicDetail.topic_name}
                    topic_id={topic}
                    toast={"Note successfully created!"}
                    topicDetail={topicDetail}
                    successNoteAdd={()=>{
                        get_map_data(username, topic);
                    }}
                    add_note
                    isOpen={notesModalStates.isOpen}
                    onOpen={notesModalStates.onOpen}
                    onClose={notesModalStates.onClose}
                    title={"Notes"}
                    selectedText={""}
                />
                <Flex
                    onClick={() => {
                        notesModalStates.onOpen();
                    }}
                    cursor={"pointer"}
                    w={"80px"}
                    h={"80px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={"claydeck.brand.primary"}
                    border={"2px solid white"}
                    boxShadow={"0px 4px 4px 0px #00000040"}
                    position={"fixed"}
                    bottom={"80px"}
                    right={"20px"}
                    borderRadius={"50px"}
                    zIndex={10}
                >
                    <Text color={"white"} margin={0} fontSize={"32px"}>
                        +
                    </Text>
                </Flex>
            </Container>
        </>
    );
};

export default NewsTopicDetail;
