export const featureFlags = {
  testsOnDailyNews: true,
  testsOnTopicDetail: true, //not usable right now
  testsOnTopicNews: false,
  mielstonesOnHomePage: false,
  buttonsOnHomePage: false,
  progressOnHomePage: false,
  testsTab: false,
  testMode: false,
  imageInNotes: true,
  myNotesPage: true,
  notesOnTimeline: false,
  noteDeletion: false,
  profileImgEdit: false,
  devSettings: true,
  devApiUrl: false,
  conceptDetailsPage: true,
  NewsDetailsPage: true,
  topbarDisplay: true,
  newHomeUI: false,
};
