import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import { AiOutlineArrowRight } from "react-icons/ai";
import { PiFunnelLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { setNewUser } from "../../redux/actions/userActions";
import { capsFirst } from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import { quiz_map } from "../../data/new_generated/quiz_map";
import EmptyImage from "../../Components/EmptyImage";
import MultiLayerDropdown from "../../Components/MultiLayerDropdown";
const Tests = () => {
  const [tab, setTab] = useState("pyq");
  const [personalizedTests, setPersonalizedTests] = useState([]);
  const [pyqTests, setPyqTests] = useState([]);
  const [practiceTests, setPracticeTests] = useState([]);
  // const [standardTests, setStandardTests] = useState(quiz_map.standard_tests);
  const navigate = useNavigate();

  const [subjectData, setSubjectData] = useState([]);
  const [subjectSelect, setSubjectSelect] = useState({});
  const username = useSelector((state) => state.user.username);


  const [topicDetail, setTopicDetail] = useState({});

  const get_map_data = async (username, workspace) => {
    const r = await axios.get(
        NEW_API_URL +
        "lessonplan/get_topic_detail/" +
        username +
        "/" +
        workspace +
        "/"
    );
    setTopicDetail(r.data.topic_detail);

    if(r?.data){
      let tests = r.data.topic_detail.tests
      let pyq_tests = r.data.topic_detail.pyq_tests
      setPyqTests(pyq_tests)
      setPracticeTests(tests)
    }

    return r.data;
  };

  const get_lesson_map_data = async (username, workspace) => {
    const r = await axios.get(
        NEW_API_URL +
        "lessonplan/get_lessonplan/" +
        username +
        "/" +
        workspace +
        "/"
    );
    console.log(r);
    setSubjectData(r.data);
    // return r.data
  };
  useEffect(() => {
    get_lesson_map_data(username, 1);
  }, []);
  useEffect(() => {
    if(subjectSelect && subjectSelect.topic){
      console.log(subjectSelect)
      get_map_data(username,subjectSelect.topic.topic_id);

    }
  }, [subjectSelect]);


  return (
    <>
      <Container
        justifyContent={"center"}
        alignItems={"center"}
        w={"95%"}
        mt={'100px'}
        maxW="container.xl"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          minH={"95vh"}
        >
          <Box>
            <Flex alignItems={"center"}>


              <Box>
                <P2xl mb={0} ml={0} mr={0}>
                  <strong>Test {subjectSelect.hasOwnProperty('topic') ? <>- {subjectSelect.topic.topic_name}</>:<></>}</strong>
                </P2xl>
              </Box>

            </Flex>


            <Tabs align={"center"}>
              <TabList>
                <Tab>
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <Image h={"20px"} w={"20px"} src={"/hintai.png"}></Image>


                    <Pxl
                        m={0}
                        style={{ fontWeight: "300" }}
                        ml={"10px"}
                        mr={"30px"}
                        cursor={"pointer"}
                        onClick={() => {
                          setTab("pyq");
                        }}
                        color={tab == "pyq" ? "#0962A2" : "black"}
                    >
                      PYQs
                    </Pxl>
                  </Flex>
                </Tab>
                <Tab>
                  <Pxl
                    style={{ fontWeight: "300" }}
                    ml={"30px"}
                    mr={"30px"}
                    cursor={"pointer"}
                    onClick={() => {
                      setTab("practice");
                    }}
                    color={tab == "practice" ? "#0962A2" : "black"}
                  >
                    Practice
                  </Pxl>
                </Tab>
                <Tab>
                  <Pxl
                      style={{ fontWeight: "300" }}
                      ml={"30px"}
                      mr={"30px"}
                      cursor={"pointer"}
                      onClick={() => {
                        setTab("personalized");
                      }}
                      color={tab == "personalized" ? "#0962A2" : "black"}
                  >
                    Personalised
                  </Pxl>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>

                  <Box mt={"16px"} position={'relative'} zIndex={10}>
                    <HeadCard title={'Topic-wise'}>
                      <Flex justifyContent={'flex-start'} mt={"16px"} position={'relative'} zIndex={10}>

                      <Box padding={'20px'}>
                        <MultiLayerDropdown onSelect={(e)=>{

                          setSubjectSelect(e)
                        }} data={subjectData} text={"Change Topic"} />
                      </Box>
                      </Flex>

                      <Flex ml={"12px"} pb={"32px"} pl={'32px'} flexWrap={"wrap"}>
                        {pyqTests.map((item, index) => (
                            <Link
                                to={
                                    "/learn/" +
                                    subjectSelect.subject.subject_id +
                                    "/" +
                                    subjectSelect.area.area_id +
                                    "/" +
                                    subjectSelect.topic.topic_id +
                                    "/tests/" +
                                    item.id
                                }
                                state={{
                                  leaderboard: false,
                                }}
                            >
                              <Flex
                                  position={"relative"}
                                  mr={"20px"}
                                  key={index}
                                  flexDirection={"column"}
                                  mt={"30px"}
                                  w={"200px"}
                                  p={"12px"}
                                  boxShadow={"0px 3px 3px 0px #00000040"}
                                  borderRadius={"12px"}
                                  borderLeft={"4px solid #2A6291"}
                                  minH={"100px"}
                              >
                                {item.read_status === "Read" || item.read_status === 'read' && (
                                    <Image
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "10px",
                                        }}
                                        src={"/read_tick.png"}
                                        height={"20px"}
                                        width={"20px"}
                                    />
                                )}


                                <Flex justify={'space-around'} alignItems={"center"}>
                                  <Box>

                                    <Center>
                                      <Image
                                          height={"64px"}
                                          width={"53px"}
                                          src={"/news_test.png"}
                                      ></Image>
                                    </Center>

                                    {/*<Flex alignItems={'center'}>*/}
                                    {/*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*/}
                                    {/*</Flex>*/}
                                    <Box mt={"10px"}>
                                      <Pmd mb={0} textAlign={"center"}>
                                        {item.title}
                                      </Pmd>
                                    </Box>
                                  </Box>
                                  { item.read_status === "Read" || item.read_status === 'read' && <Pmd>
                                    {item.score.toFixed(1)} / {item.max_score}
                                  </Pmd>}
                                </Flex>

                              </Flex>
                            </Link>
                        ))}
                      </Flex>
                    </HeadCard>
                  </Box>

                  {/*{*/}
                  {/*    pyqTests.length <= 0  && <EmptyImage message={'No tests available yet'}/>*/}
                  {/*}*/}



                </TabPanel>
                <TabPanel>
                  <Box mt={"16px"} position={'relative'} zIndex={10}>
                    <HeadCard title={'Topic-wise'}>
                      <Flex justifyContent={'flex-start'} mt={"16px"} position={'relative'} zIndex={10}>

                        <Box padding={'20px'}>
                          <MultiLayerDropdown onSelect={(e)=>{

                            setSubjectSelect(e)
                          }} data={subjectData} text={"Change Topic"} />
                        </Box>
                      </Flex>

                      <Flex ml={"12px"} pb={"32px"} pl={'32px'} flexWrap={"wrap"}>
                        {practiceTests.map((item, index) => (
                            <Link
                                to={
                                    "/learn/" +
                                    subjectSelect.subject.subject_id +
                                    "/" +
                                    subjectSelect.area.area_id +
                                    "/" +
                                    subjectSelect.topic.topic_id +
                                    "/tests/" +
                                    item.id
                                }
                                state={{
                                  leaderboard: false,
                                }}
                            >
                              <Flex
                                  position={"relative"}
                                  mr={"20px"}
                                  key={index}
                                  flexDirection={"column"}
                                  mt={"30px"}
                                  w={"200px"}
                                  p={"12px"}
                                  boxShadow={"0px 3px 3px 0px #00000040"}
                                  borderRadius={"12px"}
                                  borderLeft={"4px solid #2A6291"}
                                  minH={"150px"}
                              >
                                {item.read_status === "Read" && (
                                    <Image
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "10px",
                                        }}
                                        src={"/read_tick.png"}
                                        height={"20px"}
                                        width={"20px"}
                                    />
                                )}

                                <Flex justify={'space-around'} alignItems={"center"}>
                                  <Box>

                                    <Center>
                                      <Image
                                          height={"64px"}
                                          width={"53px"}
                                          src={"/news_test.png"}
                                      ></Image>
                                    </Center>

                                    {/*<Flex alignItems={'center'}>*/}
                                    {/*  <Plg margin={0} pl={'20px'}>3 / 10</Plg>*/}
                                    {/*</Flex>*/}
                                    <Box mt={"10px"}>
                                      <Pmd mb={0} textAlign={"center"}>
                                        {item.title}
                                      </Pmd>
                                    </Box>
                                  </Box>
                                  { item.read_status === "Read" || item.read_status === 'read' && <Pmd>
                                    {item.score.toFixed(1)} / {item.max_score}
                                  </Pmd>}
                                </Flex>
                              </Flex>
                            </Link>
                        ))}
                      </Flex>

                    </HeadCard>
                  </Box>
                  {
                      practiceTests.length <= 0  && <EmptyImage message={'No tests available yet'}/>
                  }
                </TabPanel>
                <TabPanel>
                  <EmptyImage message={'Coming Soon'}/>

                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Container>
    </>
  );
};

export default Tests;
